import { render, unmountComponentAtNode } from 'react-dom'
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'

import Calendar from './components/calendar'
import Products from './components/products'

const Booking = ({expert}) => {

   const {products} = expert

   const [product, setProduct] = useState(null) 

   useEffect(() => {
      if(products.length < 2) {
         setProduct(_.head(products))
      }
   }, [])

   if(product){
      return (
         <Calendar expert={expert} product={product} />
      )
   }

   return (
      <Products items={products} setProduct={setProduct} />
   )

}

class BookingElement extends HTMLElement {
   connectedCallback() {
      const expert = this.dataset.expert || false
      render(
         <Booking
            expert={expert?JSON.parse(expert):{}}
         />, this
      )
   }

   disconnectedCallback() {
      unmountComponentAtNode(this)
   }
}

customElements.define('booking-element', BookingElement)
